
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';

import uiActions from 'services/UI/actions';
import { IResponseDataBody } from 'services/response';
import { PATH_LOADING } from './constants';
import { IAptDetailResData } from './types/apt';
import { ICategoryItemData } from 'services/shop/types/categories';
import shopApis from 'services/shop/apis';

const getGetDetailAppointment: ISagaFunc<{ aptId: string, shopId: string }> = function* ({ payload }) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getDetailApt, result: true }));
  yield delay(500);
  try {
    const res: IResponseDataBody<IAptDetailResData> = yield apis.getAppointmentDetail(payload.aptId, payload.shopId);
    if (res.data.data) {
      yield put(actions.getGetDetailAppointment.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getGetDetailAppointment.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getDetailApt, result: false }));
  }
};

const getServicesByStaff: ISagaFunc<string> = function* ({ payload: staffId }) {
  try {
    const resData: IResponseDataBody<ICategoryItemData[]> = yield call(
      shopApis.getCategoriesByStaff, staffId
    );
    if (resData?.data?.data) {
      yield put(actions.getServicesByStaff.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.getServicesByStaff.fail({}));
  }
};

export default function* aptServiceSagas() {
  yield takeLatest(actions.getGetDetailAppointment.fetch, getGetDetailAppointment);
  yield takeLatest(actions.getServicesByStaff.fetch, getServicesByStaff);
}
