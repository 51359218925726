import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IUIQuickServiceItem } from '../QuickServices/Category';
import shopActions from 'services/shop/actions';

const initialState: IState = {
  detail: null,
  quickServices: [],
  staffCategories: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    addQuickService: (state, { payload }: { payload: IUIQuickServiceItem }) => {
      state.quickServices.push(payload);
    },
    subtractQuickService: (state, { payload }: { payload: IUIQuickServiceItem }) => {
      const idx = state.quickServices.findIndex(
        o => o.id === payload.id,
      );
      if (idx !== -1) {
        state.quickServices.splice(idx, 1);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.getServicesByStaff.fetch, (state) => {
        state.staffCategories = [];
      })
      .addCase(actions.getServicesByStaff.success, (state, { payload }) => {
        state.staffCategories = payload;
      })
      .addCase(actions.getServicesByStaff.fail, (state) => {
        state.staffCategories = [];
      })

      .addCase(shopActions.init.bookingOnline, (state) => {
        state.quickServices = [];
      })
      .addCase(actions.getGetDetailAppointment.success, (state, { payload }) => {
        state.detail = payload;
      })
      ;
  },
});

export const aptUIActions = Slice.actions;
const aptServiceReducer = Slice.reducer;
export default aptServiceReducer;
