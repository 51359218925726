import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IStaffItemData } from 'services/shop/types/staff';

const initialState: IState = {
  customer: null,
  selectedServices: [],
  selectedStaff: null,
  initialStaff: null,
  infoWalkInTicket: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    saveStaffCheckIn: (state, { payload }: { payload: IStaffItemData | null }) => {
      state.initialStaff = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.setCustomerInfo, (state, { payload }) => {
        state.customer = payload;
      })
      .addCase(actions.setSelectedServices, (state, { payload }) => {
        state.selectedServices = payload;
      })
      .addCase(actions.setSelectedStaff, (state, { payload }) => {
        state.selectedStaff = payload;
      })
      .addCase(actions.getInfoCheckInTicket.success, (state, { payload }) => {
        state.infoWalkInTicket = payload;
      })
      .addCase(actions.reset, (state) => {
        state.selectedServices = [];
        state.selectedStaff = null;
        state.infoWalkInTicket = null;
      })
      ;
  },
});

const checkInServiceReducer = Slice.reducer;
export const checkInUIActions = Slice.actions;
export default checkInServiceReducer;
