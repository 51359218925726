import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import useRefreshToken from 'hooks/useRefreshToken';
import PrivateLayout from 'components/layout/PrivateLayout';

// Lazy load các component
const GiftCardDetail = lazy(() => import('./GiftCardDetail'));
const NotFoundPage = lazy(() => import('./NotFound'));
const BookingOnlinePage = lazy(() => import('./booking'));
const BookingCompleted = lazy(() => import('./booking/BookingCompleted'));
const BookingDetail = lazy(() => import('./booking/Detail'));
const CheckInShieldLayout = lazy(() => import('./checkIn/pages/CheckInShieldLayout'));
const ChooseServicesCheckIn = lazy(() => import('./checkIn/pages/ChooseServices'));
const ChooseTypeCheckIn = lazy(() => import('./checkIn/pages/ChooseTypeCheckIn'));
const InfoCustomer = lazy(() => import('./checkIn/pages/InfoCustomer'));
const CouponPage = lazy(() => import('./coupon'));
const ReceiptPage = lazy(() => import('./receipt'));
const BuyGiftCardPage = lazy(() => import('./shopping/GiftCard/BuyGiftCard'));
const BuyGiftCardCompleted = lazy(() => import('./shopping/GiftCard/BuyGiftCardCompleted'));
const GiftCardListPage = lazy(() => import('./shopping/GiftCard/GiftCardList'));
const ChooseStaffCheckIn = lazy(() => import('./checkIn/pages/ChooseStaff'));
const SuccessCheckIn = lazy(() => import('./checkIn/pages/Success'));
const CreditPayment = lazy(() => import('./payment/pages/CreditPayment'));
const CreditPaymentSuccess = lazy(() => import('./payment/pages/Success'));
const CreditPaymentFail = lazy(() => import('./payment/pages/Fail'));
const ConfirmSuccessCus = lazy(() => import('./payment/pages/ConfirmSuccess'));
const WrappingPayment = lazy(() => import('./payment/components/WrappingPayment'));
const ReviewDetail = lazy(() => import('./review'));
const SalonSummaryReport = lazy(() => import('./report/SalonSummaryReport'));
const CustomerReferral = lazy(() => import('./customer/CustomerReferral'));

const RootScreens = () => {
  const { shield } = useRefreshToken();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="store/:shop_id" element={shield(PrivateLayout)}>
          <Route path="bill/:bill_id">
            <Route path="receipt" element={<ReceiptPage />} />
          </Route>
          <Route path="review/:review_id" element={<ReviewDetail />} />
          <Route path="coupon/:coupon_id" element={<CouponPage />} />
          <Route path="booking">
            <Route path="detail/:booking_id" element={<BookingDetail />} />
            <Route path="online" element={<BookingOnlinePage />} />
            <Route path="online/completed" element={<BookingCompleted />} />
          </Route>
          <Route path="shopping/gift-card" element={<GiftCardListPage />} />
          <Route path="gift-card/:id" element={<GiftCardDetail />} />
          <Route path="shopping/gift-card/:id">
            <Route path="" element={<BuyGiftCardPage />} />
            <Route path="completed" element={<BuyGiftCardCompleted />} />
          </Route>
          <Route path="payment/:ticketId" element={<WrappingPayment />}>
            <Route path="" element={<CreditPayment />} />
            <Route path="add-tip">
              <Route path="" element={<CreditPaymentSuccess />} />
              <Route path="success" element={<ConfirmSuccessCus />} />
            </Route>
            <Route path="fail" element={<CreditPaymentFail />} />
          </Route>
          <Route path='check-in/:station_number' element={<InfoCustomer />} />
          <Route path='check-in/:station_number' element={<CheckInShieldLayout />}>
            <Route path='choose-type' element={<ChooseTypeCheckIn />} />
            <Route path='choose-service' element={<ChooseServicesCheckIn />} />
            <Route path='choose-staff' element={<ChooseStaffCheckIn />} />
            <Route path='success/:id/:type' element={<SuccessCheckIn />} />
          </Route>
          <Route path='customer/:customer_code' element={<CustomerReferral />} />
        </Route>
        <Route path='store/:shop_id/report/summary' element={<SalonSummaryReport />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default RootScreens;