import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { ICategoryItemData } from 'services/shop/types/categories';

const getGetDetailAppointment = createAsyncAction<{ aptId: string, shopId: string }>(PREFIX_ACTIONS + 'getGetDetailAppointment');

const getServicesByStaff = createAsyncAction<string, ICategoryItemData[]>(PREFIX_ACTIONS + 'getServicesByStaff');

const aptActions = {
  getGetDetailAppointment,
  getServicesByStaff,
};

export default aptActions;
